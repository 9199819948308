import { Box, Button, Grid, InputLabel, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CurrentStep, MP } from "./zone";

const MAX_FILE_SIZE = 1024 * 5; // 1MB

export interface Application {
  customer: {
    identityDocument: {
      frontSideImage: string | null;
      backSideImage: string | null;
      selfieSideImage: string | null;
    };
  };
}

export interface IdentityCard {
  submitForm: () => void;
  setIdentityCardData: (values: Application) => void;
}

export default function IdentityImages(props: {
  handleIsLoading: (isLoading: boolean) => void;
  merchantPartner: MP;
  handleIsSystemError: (isSystemError: boolean) => void;
  handleSetNextStep: (step: number) => void;
}) {
  const [frontIdCardImageFile, setFrontIdCardImageFile] =
    React.useState<File | null>(null);
  const [frontIdCardImagePreview, setFrontIdCardImagePreview] = React.useState<
    string | undefined
  >("Mask Group 1@2x.png");
  const frontIdCardInputRef = React.useRef<HTMLInputElement>(null);
  const [backIdCardImageFile, setBackIdCardImageFile] =
    React.useState<File | null>(null);
  const [backIdCardImagePreview, setBackIdCardImagePreview] = React.useState<
    string | undefined
  >("Group 10853@2x.png");
  const [selfieImageFile, setSelfieImageFile] = React.useState<File | null>(
    null
  );
  const [selfieImagePreview, setSelfieImagePreview] = React.useState<
    string | undefined
  >("Group 10854@2x.png");
  const selfieInputRef = React.useRef<HTMLInputElement>(null);
  const backIdCardInputRef = React.useRef<HTMLInputElement>(null);
  const [currentIndexButton, setCurrentIndexButton] = useState<number>(1);
  const [identitydocumentSource, setIdentitydocumentSource] =
    useState<Application>({
      customer: {
        identityDocument: {
          frontSideImage: null,
          backSideImage: null,
          selfieSideImage: null,
        },
      },
    });
  const frontImageTab = useRef<HTMLDivElement>(null);
  const backImageTab = useRef<HTMLDivElement>(null);

  const formikFrontImage = useFormik({
    initialValues: identitydocumentSource,
    validationSchema: Yup.object().shape({
      customer: Yup.object().shape({
        identityDocument: Yup.object().shape({
          frontSideImage: Yup.string()
            .test(
              "frontSideImage",
              "ID card front side is required !",
              (value) => {
                if (value === undefined || value === null) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "frontSideImage",
              "Max size reached of 1mb per picture !",
              (value) => {
                if (value !== undefined || value !== null) {
                  const fileSize: number | undefined =
                    frontIdCardImageFile?.size;
                  const fileSizeKiloBytes: number =
                    fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

                  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setFrontIdCardImagePreview("Mask Group 1@2x.png");
                    return false;
                  }
                }

                return true;
              }
            ),
        }),
      }),
    }),
    onSubmit: async (values) => {
      if (backImageTab !== null && frontImageTab !== null) {
        backImageTab.current?.classList.remove("identityCardDocTabInActive");
        backImageTab.current?.classList.add("identityCardDocTabActive");

        frontImageTab.current?.classList.remove("identityCardDocTabActive");
        frontImageTab.current?.classList.add("identityCardDocTabInActive");

        setCurrentIndexButton(2);
      }

      setIdentitydocumentSource((prevState) => ({
        ...prevState,
        customer: {
          identityDocument: {
            frontSideImage: values.customer.identityDocument.frontSideImage,
            backSideImage: prevState.customer.identityDocument.backSideImage,
            selfieSideImage:
              prevState.customer.identityDocument.selfieSideImage,
          },
        },
      }));
    },
  });

  const formikBackImage = useFormik({
    initialValues: identitydocumentSource,
    validationSchema: Yup.object().shape({
      customer: Yup.object().shape({
        identityDocument: Yup.object().shape({
          backSideImage: Yup.string()
            .test("backSideImage", "ID back side is required !", (value) => {
              if (value === undefined || value === null) {
                return false;
              }
              return true;
            })
            .test(
              "backSideImage",
              "Max size reached of 1mb per picture !",
              (value) => {
                if (value !== undefined || value !== null) {
                  const fileSize: number | undefined =
                    backIdCardImageFile?.size;
                  const fileSizeKiloBytes: number =
                    fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

                  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setBackIdCardImagePreview("Group 10853@2x.png");
                    return false;
                  }
                }

                return true;
              }
            ),
        }),
      }),
    }),
    onSubmit: async (values) => {
      setCurrentIndexButton(3);

      setIdentitydocumentSource((prevState) => ({
        ...prevState,
        customer: {
          identityDocument: {
            frontSideImage: prevState.customer.identityDocument.frontSideImage,
            backSideImage: values.customer.identityDocument.backSideImage,
            selfieSideImage:
              prevState.customer.identityDocument.selfieSideImage,
          },
        },
      }));
    },
  });

  const formikSelfieImage = useFormik({
    initialValues: identitydocumentSource,
    validationSchema: Yup.object().shape({
      customer: Yup.object().shape({
        identityDocument: Yup.object().shape({
          selfieSideImage: Yup.string()
            .test(
              "selfieSideImage",
              "Selfie side image is required !",
              (value) => {
                if (value === undefined || value === null) {
                  return false;
                }
                return true;
              }
            )
            .test(
              "selfieSideImage",
              "Max size reached of 1mb per picture !",
              (value) => {
                if (value !== undefined || value !== null) {
                  const fileSize: number | undefined = selfieImageFile?.size;
                  const fileSizeKiloBytes: number =
                    fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

                  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setSelfieImagePreview("Group 10853@2x.png");
                    return false;
                  }
                }

                return true;
              }
            ),
        }),
      }),
    }),
    onSubmit: async (values) => {
      setIdentitydocumentSource((prevState) => ({
        ...prevState,
        customer: {
          identityDocument: {
            frontSideImage: prevState.customer.identityDocument.frontSideImage,
            backSideImage: prevState.customer.identityDocument.backSideImage,
            selfieSideImage: values.customer.identityDocument.selfieSideImage,
          },
        },
      }));
    },
  });

  let handleUploadIdentityCardsToDMS = async () => {
    props.handleIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/DMSManager-UploadIdentityCardToDMS`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
        body: JSON.stringify({
          base64FrontPictureIDCard:
            identitydocumentSource.customer.identityDocument.frontSideImage,
          base64BackPictureIDCard:
            identitydocumentSource.customer.identityDocument.backSideImage,
          base64SelfyPicture:
            identitydocumentSource.customer.identityDocument.selfieSideImage,
        }),
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          props.handleIsSystemError(true);
        } else {
          const response: any = await data.json();
          if (response.result === true) {
            props.handleSetNextStep(CurrentStep.IDCardUpload);
          }
        }

        props.handleIsLoading(false);
      })
      .catch((error) => {
        props.handleIsSystemError(false);
        props.handleIsLoading(false);
      });
  };

  useEffect(() => {
    if (
      identitydocumentSource.customer.identityDocument.frontSideImage !==
        null &&
      identitydocumentSource.customer.identityDocument.backSideImage !== null &&
      identitydocumentSource.customer.identityDocument.selfieSideImage !== null
    ) {
      handleUploadIdentityCardsToDMS();
    }
  }, [identitydocumentSource]);

  useEffect(() => {
    const fileSize: number | undefined = frontIdCardImageFile?.size;
    const fileSizeKiloBytes: number =
      fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setFrontIdCardImagePreview(undefined);
      setFrontIdCardImageFile(null);
      formikFrontImage.setFieldValue(
        "customer.identityDocument.frontSideImage",
        -1
      );
      formikFrontImage.validateField(
        "customer.identityDocument.frontSideImage"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontIdCardImageFile]);

  useEffect(() => {
    const fileSize: number | undefined = backIdCardImageFile?.size;
    const fileSizeKiloBytes: number =
      fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setBackIdCardImagePreview(undefined);
      setBackIdCardImageFile(null);
      formikBackImage.setFieldValue(
        "customer.identityDocument.backSideImage",
        -1
      );
      formikBackImage.validateField("customer.identityDocument.backSideImage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backIdCardImageFile]);

  useEffect(() => {
    const fileSize: number | undefined = selfieImageFile?.size;
    const fileSizeKiloBytes: number =
      fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setSelfieImagePreview(undefined);
      setSelfieImageFile(null);
      formikSelfieImage.setFieldValue(
        "customer.identityDocument.selfieSideImage",
        -1
      );
      formikSelfieImage.validateField(
        "customer.identityDocument.selfieSideImage"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfieImageFile]);

  const fileToBase64 = (file: File | Blob | null): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };

      if (file !== undefined && file !== null) {
        reader.readAsDataURL(file);
        reader.onerror = reject;
      }
    });

  const handleFirstIDCardUploadClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const fileSize: number | undefined = event?.target?.files?.item(0)?.size;
      const fileSizeKiloBytes: number =
        fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;
      setFrontIdCardImageFile(event.target.files?.item(0));

      if (fileSizeKiloBytes > MAX_FILE_SIZE === false) {
        fileToBase64(event.target.files?.item(0)).then((value) => {
          setFrontIdCardImagePreview(value);
          const formatBase64 = value.substring(value.indexOf(",") + 1);

          formikFrontImage.setFieldValue(
            "customer.identityDocument.frontSideImage",
            formatBase64
          );
        });
      }
    }
  };

  const handleBackIDCardUploadClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const fileSize: number | undefined = event?.target?.files?.item(0)?.size;
      const fileSizeKiloBytes: number =
        fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;

      setBackIdCardImageFile(event.target.files?.item(0));

      if (fileSizeKiloBytes > MAX_FILE_SIZE === false) {
        fileToBase64(event.target.files?.item(0)).then((value) => {
          setBackIdCardImagePreview(value);
          const formatBase64 = value.substring(value.indexOf(",") + 1);

          formikBackImage.setFieldValue(
            "customer.identityDocument.backSideImage",
            formatBase64
          );
        });
      }
    }
  };

  const handleSelfieIDCardUploadClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files !== null && event.target.files.length > 0) {
      const fileSize: number | undefined = event?.target?.files?.item(0)?.size;
      const fileSizeKiloBytes: number =
        fileSize !== undefined ? fileSize / MAX_FILE_SIZE : 0;
      setSelfieImageFile(event.target.files?.item(0));

      if (fileSizeKiloBytes > MAX_FILE_SIZE === false) {
        fileToBase64(event.target.files?.item(0)).then((value) => {
          setSelfieImagePreview(value);
          const formatBase64 = value.substring(value.indexOf(",") + 1);

          formikSelfieImage.setFieldValue(
            "customer.identityDocument.selfieSideImage",
            formatBase64
          );
        });
      }
    }
  };

  function handleContinueFrontBackImage() {
    if (
      frontIdCardImageFile !== null &&
      backIdCardImageFile === null &&
      currentIndexButton === 1
    ) {
      formikFrontImage.submitForm();
    } else if (
      frontIdCardImageFile !== null &&
      backIdCardImageFile !== null &&
      currentIndexButton === 2
    ) {
      formikBackImage.submitForm();
    }
  }

  function handleRegisterImages() {
    formikSelfieImage.submitForm();
  }

  return (
    <>
      <div className="container flex-shrink-0">
        <main role="main">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div>
                <ul className="progress-bar-wrapper">
                  <li className="step step-active">
                    <span className="step-item">
                      <svg
                        id="icon_identification"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <rect
                          id="Rectangle_5768"
                          data-name="Rectangle 5768"
                          width="32"
                          height="32"
                          fill="none"
                        />
                        <g
                          id="Group_10733"
                          data-name="Group 10733"
                          transform="translate(7.734 6.107)"
                        >
                          <path
                            id="Path_8743"
                            data-name="Path 8743"
                            d="M32.486,23.2A4.588,4.588,0,1,0,27.893,18.6,4.6,4.6,0,0,0,32.486,23.2Z"
                            transform="translate(-24.22 -14.021)"
                            fill="#00915a"
                          />
                          <path
                            id="Path_8744"
                            data-name="Path 8744"
                            d="M34.745,49.033v-.921A5.053,5.053,0,0,0,29.7,43.063H23.261a5.054,5.054,0,0,0-5.049,5.049v.921a1.378,1.378,0,0,0,1.376,1.376H33.369A1.378,1.378,0,0,0,34.745,49.033Z"
                            transform="translate(-18.212 -32.044)"
                            fill="#00915a"
                          />
                        </g>
                        <path
                          id="Path_8745"
                          data-name="Path 8745"
                          d="M5.661,31.525v-4.34H7.444v4.34a.818.818,0,0,0,.817.816H12.6v1.783H8.261A2.6,2.6,0,0,1,5.661,31.525ZM12.6,7.444V5.661H8.261a2.6,2.6,0,0,0-2.6,2.6V12.6H7.444V8.261a.818.818,0,0,1,.817-.817Zm19.741.817V12.6h1.783V8.261a2.6,2.6,0,0,0-2.6-2.6h-4.34V7.444h4.34A.818.818,0,0,1,32.342,8.261Zm1.783,23.264v-4.34H32.342v4.34a.818.818,0,0,1-.816.816h-4.34v1.783h4.34A2.6,2.6,0,0,0,34.125,31.525Z"
                          transform="translate(-3.893 -3.893)"
                          fill="#212121"
                        />
                      </svg>
                    </span>
                    <span className="step-text section-heading">
                      Идентификация
                    </span>
                  </li>
                </ul>
              </div>
              <div className="container rounded bg-white shadow my-4">
                <div className="main-content">
                  <div className="row justify-content-sm-center">
                    <div className="col-12 pt-4">
                      <div className="row justify-content-center g-0 g-sm-1 g-md-2">
                        <div className="col-12 d-flex justify-content-center">
                          {(currentIndexButton === 1 ||
                            currentIndexButton === 2) && (
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent={"center"}
                                direction={"column"}
                                alignItems={"center"}
                              >
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    className="idCardHeader"
                                    gutterBottom
                                    align="center"
                                  >
                                    Добави снимка на лична карта
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    className="idCardBottomHeader"
                                    gutterBottom
                                    align="center"
                                  >
                                    Трябва ни само за идентификация
                                  </Typography>
                                </Grid>
                                <Grid>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Box
                                      ref={frontImageTab}
                                      className="identityCardDocTabActive"
                                      textAlign={"center"}
                                    >
                                      <Box sx={{ marginTop: 1.4 }}>Лице</Box>
                                    </Box>
                                    <Box
                                      ref={backImageTab}
                                      className="identityCardDocTabInActive"
                                      textAlign={"center"}
                                    >
                                      <Box sx={{ marginTop: 1.4 }}>Гръб</Box>
                                    </Box>
                                  </Box>
                                  {currentIndexButton === 1 && (
                                    <Box
                                      sx={{
                                        justifyContent: "center",
                                        p: 2,
                                      }}
                                      className="image-area"
                                    >
                                      <label
                                        style={{ display: "contents" }}
                                        htmlFor="contained-button-file-first-image"
                                      >
                                        <img
                                          width="100%"
                                          src={frontIdCardImagePreview}
                                          alt="front id card"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </label>
                                      <Box
                                        component={"a"}
                                        className="remove-image"
                                        style={{ display: "inline" }}
                                        onClick={() => {
                                          if (
                                            frontIdCardInputRef &&
                                            frontIdCardInputRef.current !== null
                                          )
                                            frontIdCardInputRef.current.value =
                                              "";

                                          setFrontIdCardImagePreview(
                                            "Mask Group 1@2x.png"
                                          );
                                          setFrontIdCardImageFile(null);
                                        }}
                                      >
                                        &#215;
                                      </Box>
                                    </Box>
                                  )}
                                  {currentIndexButton === 2 && (
                                    <Box
                                      sx={{
                                        justifyContent: "center",
                                        p: 2,
                                      }}
                                      className="image-area"
                                    >
                                      <label
                                        style={{ display: "contents" }}
                                        htmlFor="contained-button-file-back-image"
                                      >
                                        <img
                                          width="100%"
                                          src={backIdCardImagePreview}
                                          alt="front id card"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </label>
                                      <Box
                                        component={"a"}
                                        className="remove-image"
                                        style={{ display: "inline" }}
                                        onClick={() => {
                                          if (
                                            backIdCardInputRef &&
                                            backIdCardInputRef.current !== null
                                          )
                                            backIdCardInputRef.current.value =
                                              "";

                                          setBackIdCardImagePreview(
                                            "Group 10853@2x.png"
                                          );
                                          setBackIdCardImageFile(null);
                                        }}
                                      >
                                        &#215;
                                      </Box>
                                    </Box>
                                  )}
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <InputLabel className="errorText">
                                    {
                                      formikFrontImage.errors.customer
                                        ?.identityDocument?.frontSideImage
                                    }
                                  </InputLabel>
                                  <InputLabel className="errorText">
                                    {
                                      formikBackImage.errors.customer
                                        ?.identityDocument?.backSideImage
                                    }
                                  </InputLabel>
                                </Grid>
                                {((frontIdCardImageFile === null &&
                                  currentIndexButton === 1) ||
                                  (backIdCardImageFile === null &&
                                    currentIndexButton === 2)) && (
                                  <Grid item md={6} xs={12}>
                                    <Box style={{ float: "left" }}>
                                      <img
                                        src="icon_personal_information.svg"
                                        alt={""}
                                      />
                                    </Box>
                                    <Box style={{ float: "left" }}>
                                      <Typography
                                        className="idCardBottomImageHeader"
                                        gutterBottom
                                        align="center"
                                      >
                                        Информацията да е ясно видима
                                      </Typography>
                                    </Box>
                                  </Grid>
                                )}
                                {((frontIdCardImageFile !== null &&
                                  currentIndexButton === 1) ||
                                  (backIdCardImageFile !== null &&
                                    currentIndexButton === 2)) && (
                                  <Grid item md={6} xs={12}>
                                    <Typography
                                      className="idCardBottomImageHeader"
                                      gutterBottom
                                      align="center"
                                    >
                                      <img
                                        src="Group 10823.svg"
                                        style={{ marginRight: "5px" }}
                                        alt={""}
                                      />
                                      Провери дали виждаш ясно данните на
                                      снимката
                                    </Typography>
                                  </Grid>
                                )}
                                {/* <Grid item md={6} xs={12}>
                                <Typography className="idCardBottomImageHint" gutterBottom>
                                  Ако нямаш на разположение, можеш да качиш снимки от телефона си, а
                                  след това да завършиш процеса тук.
                                </Typography>
                              </Grid> */}
                                <Grid
                                  item
                                  md={6}
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                  }}
                                >
                                  <input
                                    accept="image/*"
                                    id="contained-button-file-back-image"
                                    style={{ display: "none" }}
                                    type="file"
                                    capture="environment"
                                    onChange={handleBackIDCardUploadClick}
                                    ref={backIdCardInputRef}
                                  />
                                  {backIdCardImageFile === null &&
                                    currentIndexButton === 2 && (
                                      <Box>
                                        <label htmlFor="contained-button-file-back-image">
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            component="span"
                                            className={
                                              props.merchantPartner.valueOf() ===
                                              MP.Emag
                                                ? "btn-large-primary-emag"
                                                : "btn-large-primary"
                                            }
                                            style={{ width: "300px" }}
                                          >
                                            Добави снимка на гърба
                                          </Button>
                                        </label>
                                      </Box>
                                    )}
                                  <input
                                    accept="image/*"
                                    id="contained-button-file-first-image"
                                    style={{ display: "none" }}
                                    type="file"
                                    capture="environment"
                                    onChange={handleFirstIDCardUploadClick}
                                    ref={frontIdCardInputRef}
                                  />
                                  {frontIdCardImageFile === null &&
                                    currentIndexButton === 1 && (
                                      <Box>
                                        <label htmlFor="contained-button-file-first-image">
                                          <Button
                                            variant="contained"
                                            component="span"
                                            className={
                                              props.merchantPartner.valueOf() ===
                                              MP.Emag
                                                ? "btn-large-primary-emag"
                                                : "btn-large-primary"
                                            }
                                            style={{ width: "300px" }}
                                          >
                                            Снимай лична карта
                                          </Button>
                                        </label>
                                      </Box>
                                    )}

                                  {((frontIdCardImageFile !== null &&
                                    currentIndexButton === 1) ||
                                    (backIdCardImageFile !== null &&
                                      currentIndexButton === 2)) && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      onClick={handleContinueFrontBackImage}
                                      sx={{ marginBottom: 1.1 }}
                                      className={
                                        props.merchantPartner.valueOf() ===
                                        MP.Emag
                                          ? "btn-large-primary-emag"
                                          : "btn-large-primary"
                                      }
                                      style={{ width: "300px" }}
                                    >
                                      Продължи
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          )}
                          {currentIndexButton === 3 && (
                            <Box>
                              <Grid
                                container
                                spacing={1}
                                justifyContent={"center"}
                                direction={"column"}
                                alignItems={"center"}
                              >
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    className="idCardHeader"
                                    gutterBottom
                                    align="center"
                                  >
                                    Добави селфи с лична карта
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography
                                    className="idCardBottomHeader"
                                    gutterBottom
                                    align="center"
                                  >
                                    То ще се използва за твоята идентификация.
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Box
                                    sx={{
                                      justifyContent: "center",
                                      p: 2,
                                    }}
                                    className="image-area"
                                  >
                                    <label
                                      style={{ display: "contents" }}
                                      htmlFor="contained-button-file-selfie-image"
                                    >
                                      <img
                                        width="100%"
                                        src={selfieImagePreview}
                                        alt="front id card"
                                        style={{
                                          display: "block",
                                          marginLeft: "auto",
                                          marginRight: "auto",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                    <Box
                                      component={"a"}
                                      className="remove-image"
                                      style={{ display: "inline" }}
                                      onClick={() => {
                                        if (
                                          selfieInputRef &&
                                          selfieInputRef.current !== null
                                        )
                                          selfieInputRef.current.value = "";

                                        setSelfieImagePreview(
                                          "Group 10854@2x.png"
                                        );
                                        setSelfieImageFile(null);
                                      }}
                                    >
                                      &#215;
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <InputLabel className="errorText">
                                    {
                                      formikSelfieImage.errors.customer
                                        ?.identityDocument?.frontSideImage
                                    }
                                  </InputLabel>
                                  <InputLabel className="errorText">
                                    {
                                      formikSelfieImage.errors.customer
                                        ?.identityDocument?.backSideImage
                                    }
                                  </InputLabel>
                                  <InputLabel className="errorText">
                                    {
                                      formikSelfieImage.errors.customer
                                        ?.identityDocument?.selfieSideImage
                                    }
                                  </InputLabel>
                                </Grid>
                                <Grid
                                  item
                                  md={10}
                                  xs={12}
                                  sx={{ marginBottom: 3 }}
                                >
                                  <Box style={{ float: "left" }}>
                                    <img
                                      src="icon_user.svg"
                                      style={{ width: "30px", height: "30px" }}
                                      alt={""}
                                    />
                                  </Box>
                                  <Box
                                    style={{ float: "left", width: "285px" }}
                                  >
                                    <Typography
                                      className="idCardBottomImageHeader"
                                      gutterBottom
                                      align="center"
                                    >
                                      Лицето ти трябва да се вижда и цялата
                                      информация на картата да бъде четима
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                justifyContent={"center"}
                                direction={"column"}
                                alignItems={"center"}
                                sx={{ marginBottom: 3 }}
                              >
                                <input
                                  accept="image/*"
                                  id="contained-button-file-selfie-image"
                                  style={{ display: "none" }}
                                  type="file"
                                  capture="environment"
                                  onChange={handleSelfieIDCardUploadClick}
                                  ref={selfieInputRef}
                                />
                                <label htmlFor="contained-button-file-selfie-image">
                                  {selfieImageFile === null && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      sx={{ width: "322px" }}
                                      size="large"
                                      className={
                                        props.merchantPartner.valueOf() ===
                                        MP.Emag
                                          ? "btn-large-primary-emag"
                                          : "btn-large-primary"
                                      }
                                      style={{ width: "300px" }}
                                    >
                                      Снимай
                                    </Button>
                                  )}
                                  {selfieImageFile !== null && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      sx={{ width: "322px" }}
                                      size="large"
                                      type="submit"
                                      onClick={handleRegisterImages}
                                      className={
                                        props.merchantPartner.valueOf() ===
                                        MP.Emag
                                          ? "btn-large-primary-emag"
                                          : "btn-large-primary"
                                      }
                                      style={{ width: "300px" }}
                                    >
                                      Регистрирай
                                    </Button>
                                  )}
                                </label>
                              </Grid>
                            </Box>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
