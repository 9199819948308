import { createTheme } from "@mui/material/styles";
import { bgBG as coreBgBG } from "@mui/material/locale";

export const mainTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#00915A",
      },
      secondary: {
        main: "#a6e22e",
      },
      //   text: {
      //     primary: "#228B22",
      //     secondary: "#a6e22e",
      //     inherit: "#a6e22e"
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1910,
      },
    },
  },
  coreBgBG
);
