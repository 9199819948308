
export const ApplicationFooter = () => {
  return (
    <>
      <footer className="footer mt-auto pt-2 pb-3 py-lg-2 g-0">
          <div className="container h-100">
            <div className="row h-100 justify-content-center">
              <div className="col-12 col-md-8 col-lg-6 p-0">
                <div className="d-md-none w-100 d-flex justify-content-center">
                  <span className="pr-md-5">PB Personal Finance</span><span>&nbsp;</span><span>–</span><span>&nbsp;</span><p className="pl-md-5 m-0 d-inline-flex">developed by Data Solutions Ltd.</p>
                </div>
                <div className="d-none d-md-flex justify-content-center">
                  <span className="pr-md-5"><span>&nbsp;</span>PB Personal Finance<span>&nbsp;</span></span><span className="pl-md-5"><span>&nbsp;</span>Developed by Data Solutions Ltd.</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
    </>
  );
};
