import { Grid, Container, AlertTitle, Alert } from "@mui/material";

export const Error = (props: { traceId: string }) => {
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item>
            <img src="maintenance.png" alt="be happy" width={278} />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={10}>
            <Alert severity="error">
              <AlertTitle>
                Error: <strong>{"statusCode"}</strong>
              </AlertTitle>
              Възкникна грешка в системата. Моля, да ни извините.
            </Alert>
            <Alert severity="info">
              <AlertTitle>
                TraceId: <strong>{"traceId"}</strong>
              </AlertTitle>
              {"message"}
            </Alert>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
