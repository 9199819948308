import { MP } from "./zone";

export default function WaitingScreen(props: { merchantPartner: MP }) {
  return (
    <>
      <div className="container flex-shrink-0" style = {{marginTop:"10px"}}>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div>
                <ul className="progress-bar-wrapper">
                  <li className="step step-active">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          className="icon-background-color"
                          width="40"
                          height="40"
                        ></rect>
                        <path
                          className="icon-accent-color"
                          d="M772.808,3.726a2,2,0,0,0-2.824.2L749.571,27.321,737.16,16.408a2,2,0,1,0-2.62,3.029l13.924,12.222a2,2,0,0,0,2.825-.2L773.012,6.55A2,2,0,0,0,772.808,3.726Z"
                          transform="translate(-733.674 -1.619)"
                        ></path>
                        <path
                          className="icon-secondary-color"
                          d="M773.262,20a4.59,4.59,0,0,1,.242,1.463V42.191a4.619,4.619,0,0,1-4.614,4.615H748.161a4.62,4.62,0,0,1-4.615-4.615V31.335l2,1.73v9.125a2.618,2.618,0,0,0,2.615,2.615H768.89a2.617,2.617,0,0,0,2.614-2.615V22.03Zm-25.1-3.151a4.619,4.619,0,0,0-4.615,4.614V23.4l2,1.694V21.462a2.617,2.617,0,0,1,2.615-2.614h18.156l1.73-2Z"
                          transform="translate(-738.523 -8.424)"
                        ></path>
                      </svg>
                    </span>
                    <span className="step-text section-heading">Моля изчакайте</span>
                  </li>
                </ul>
              </div>
              <div className="container rounded bg-white shadow my-4">
                <div className="main-content">
                  <div
                    id="waitingScreen"
                    className="waiting-screen"
                    style={{ paddingTop: "10%" }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center flex-column">
                          <div className="waiting-animation waiting-gif">
                            <div className="left-circle-wrapper slide-right">
                              <div
                                className={
                                  props.merchantPartner.valueOf() === MP.Emag
                                    ? "circle left-circle-color-emag"
                                    : "circle left-circle-color"
                                }
                              ></div>
                            </div>
                            <div className="right-circle-wrapper slide-left">
                              <div
                                className={
                                  props.merchantPartner.valueOf() === MP.Emag
                                    ? "circle right-circle-color-emag"
                                    : "circle right-circle-color"
                                }
                              ></div>
                            </div>
                          </div>
                          <div className="col-12 py-4 text-center font-weight-bold">
                            <h2 className="col-12 position-absolute static-text">
                              Подготвяме документите ти
                            </h2>
                            <h2 className="col-12 static-text-2">
                              Генерираме договора ти
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="row text-center secondary-text-color d-flex justify-content-center pb-5">
                        <span className="col-12 col-md-10">
                          Всеки момент ще те прехвърлим към следващия етап.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}
