import { MuiOtpInput } from "mui-one-time-password-input";
import React from "react";
import { CurrentStep, MP } from "./zone";
import styled from "@emotion/styled";
import { CreditInfo } from "./zone";

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 10px;
  max-width: 350px;
  margin-inline: auto;
`;

const OTP = (props: {
  merchantPartner: MP;
  handleSetNextStep: (step: number) => void;
  handleIsSystemError: (isSystemError: boolean) => void;
  handleIsLoading: (isLoading: boolean) => void;
  creditInfo: CreditInfo | null;
}) => {
  const [counter, setCounter] = React.useState(60);
  const [otp, setOtp] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handleComplete = (finalValue: string) => {
    setOtp(finalValue);
  };

  const handleConfirmOTP = async () => {
    if (otp === undefined || otp?.length! <= 5) {
      alert("Невалидна парола");
    } else {
      props.handleIsLoading(true);
      await fetch(
        `${process.env.REACT_APP_SZ_BFF_URL}/SendDocumentOfflineActivate-ActivateOTP?codeSMS=${otp}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "x-csrf": "1",
          },
        }
      )
        .then(async (data) => {
          if (data.status === 400) {
            props.handleIsSystemError(true);
          } else {
            const response: any = await data.json();
            if (response.result === true) {
              await props.handleSetNextStep(CurrentStep.VerifyOTP);
            }
          }

          props.handleIsLoading(false);
        })
        .catch((error) => {
          props.handleIsSystemError(true);
          props.handleIsLoading(false);
        });
    }
  };

  async function resendOtp() {
    props.handleIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/SendDocumentOfflineActivate-ResendOTP`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          props.handleIsSystemError(true);
        } else {
          const response = await data.json();
        }

        props.handleIsLoading(false);
      })
      .catch((error) => {
        props.handleIsSystemError(true);
        props.handleIsLoading(false);
      });
  }

  return (
    <>
      <div className="container flex-shrink-0">
        <main role="main">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div>
                <ul className="progress-bar-wrapper">
                  <li className="step step-active">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          className="icon-background-color"
                          width="40"
                          height="40"
                        ></rect>
                        <path
                          className="icon-accent-color"
                          d="M772.808,3.726a2,2,0,0,0-2.824.2L749.571,27.321,737.16,16.408a2,2,0,1,0-2.62,3.029l13.924,12.222a2,2,0,0,0,2.825-.2L773.012,6.55A2,2,0,0,0,772.808,3.726Z"
                          transform="translate(-733.674 -1.619)"
                        ></path>
                        <path
                          className="icon-secondary-color"
                          d="M773.262,20a4.59,4.59,0,0,1,.242,1.463V42.191a4.619,4.619,0,0,1-4.614,4.615H748.161a4.62,4.62,0,0,1-4.615-4.615V31.335l2,1.73v9.125a2.618,2.618,0,0,0,2.615,2.615H768.89a2.617,2.617,0,0,0,2.614-2.615V22.03Zm-25.1-3.151a4.619,4.619,0,0,0-4.615,4.614V23.4l2,1.694V21.462a2.617,2.617,0,0,1,2.615-2.614h18.156l1.73-2Z"
                          transform="translate(-738.523 -8.424)"
                        ></path>
                      </svg>
                    </span>
                    <span className="step-text section-heading">Договор</span>
                  </li>
                </ul>
              </div>
              <div className="container rounded bg-white shadow my-4">
                <div className="main-content">
                  <div className="row justify-content-sm-center text-md-center mb-10 pb-5">
                    <div className="col-12 col-md-8">
                      <h1
                        className="pb-3 pt-4 m-0 font-weight-bold"
                        style={{ textAlign: "center" }}
                      >
                        Подпиши своя договор
                      </h1>
                      <p
                        className="mb-0 text-body-2 secondary-text-color"
                        style={{ textAlign: "center" }}
                      >
                        Ще получиш SMS с еднократна парола на телефонен номер
                        +359 *** *** {props.creditInfo?.customerPhone.substring(
                          props.creditInfo?.customerPhone.length - 3
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-sm-center">
                    <div className="col-12 d-flex justify-content-center">
                      {" "}
                      {props.merchantPartner === MP.Default && (
                        <img src="icon_otp_code.svg" className="icon-object" />
                      )}
                      {props.merchantPartner === MP.Emag && (
                        <img
                          src="icon_otp_code_emag.svg"
                          className="icon-object"
                        />
                      )}
                    </div>
                    <div className="col-12 pt-4">
                      <div className="row justify-content-center g-0 g-sm-1 g-md-2">
                        <div className="col-12 d-flex justify-content-center">
                          <MuiOtpInputStyled
                            value={otp}
                            onChange={handleChange}
                            onComplete={handleComplete}
                            length={6}
                            autoFocus
                            validateChar={(character: string, index: number) =>
                              true
                            }
                            maxWidth={300}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-5 pb-5 text-center py-3">
                          <div className="text-center">
                            <p
                              id="timeRemainingMessage"
                              className="caption secondary-text-color"
                            >
                              Може да заявиш нова еднократна парола след{" "}
                              <span id="secondsLeft">{counter}</span>
                            </p>
                            {counter === 0 && (
                              <a
                                id="sendNewCodeLink"
                                className={
                                  props.merchantPartner.valueOf() === MP.Emag
                                    ? "merchant-text-color-emag"
                                    : "merchant-text-color"
                                }
                                onClick={() => {
                                  resendOtp();
                                }}
                              >
                                Изпрати нова парола
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-sm-10 pt-4 pb-4 text-center d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={otp === undefined || otp?.length! <= 5}
                        className={
                          props.merchantPartner.valueOf() === MP.Emag
                            ? "btn-large-primary-emag"
                            : "btn-large-primary"
                        }
                        onClick={handleConfirmOTP}
                        style={{ width: "300px" }}
                      >
                        Потвърди
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default OTP;
