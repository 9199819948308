import "./App.css";
import Zone from "./zone";
import { AuthProvider } from "../src/context/AuthContext";

function App() {
  return (
    <AuthProvider>
      <Zone />
    </AuthProvider>
  );
}

export default App;
