import { Button, styled } from "@mui/material";
import { MP } from "./zone";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { CreditInfo } from "./zone";

const ThankYou = (props: {
  merchantPartner: MP;
  handleIsSystemError: (isSystemError: boolean) => void;
  handleSetNextStep: (step: number) => void;
  creditInfo: CreditInfo | null;
}) => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  const redirectTo = () => {
    if (props.merchantPartner === MP.Emag)
      window.location.href = props.creditInfo?.merchantReturnURL!;
  };

  return (
    <>
      <div className="container flex-shrink-0">
        <main role="main">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div className="container rounded bg-white shadow my-3 my-md-5">
                <div className="main-content">
                  <div className="row justify-content-sm-center pt-5">
                    <div className="col-12">
                      <div className="row justify-content-center g-0 g-sm-1 g-md-2">
                        <div className="col-12 d-flex justify-content-center">
                          <img src="Great work.svg" />
                        </div>
                      </div>
                    </div>

                    {(props.creditInfo?.type === "Retail.DTSOpening" ||
                      props.creditInfo?.type === "Retail.DTSUsage") && (
                      <div>
                        <div className="col-12 text-center pb-4 pt-5 p-0">
                          <h1 className="font-weight-bold mx-3 my-0">
                            Успешно подписа твоя договор{" "}
                            {props.creditInfo?.code}!
                          </h1>
                        </div>
                        <div className="col-12 secondary-text-color pb-2 p-0">
                          <div className="row justify-content-center secondary-text-color text-center mx-3">
                            <div className="col-12 col-lg-7 pb-4">
                              <p className="m-0">
                                Договорът ще бъде изпратен на твоя имейл, заедно
                                с инструкции за достъп до твоята{" "}
                                <Button
                                  aria-describedby={id}
                                  onClick={handleClick}
                                  component="span"
                                  style={{
                                    color: "#00915A",
                                    textTransform: "none",
                                    padding: 0,
                                  }}
                                >
                                  Инфо Карта
                                </Button>
                                <BasePopup id={id} open={open} anchor={anchor}>
                                  <PopupBody>
                                    <div style={{ width: "300px" }}>
                                      Инфо Картa ти дава всичко важно за кредита
                                      и плащанията ти на едно място в телефона,
                                      безплатно. Получаваш я бързо и лесно, с
                                      няколко клика.
                                    </div>
                                  </PopupBody>
                                </BasePopup>
                                . Може да продължиш да пазаруваш, твоят оставащ
                                лимит е{" "}
                                {props.creditInfo?.currentDisposableAmount} лв.
                                Благодарим ти, че избра ПБ Лични финанси за свой
                                партньор!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {props.creditInfo?.type === "Retail.VAT" && (
                      <div>
                        <div className="col-12 text-center pb-4 pt-5 p-0">
                          <h1 className="font-weight-bold mx-3 my-0">
                            Успешно подписа твоя договор {props.creditInfo.code}
                            !
                          </h1>
                        </div>
                        <div className="col-12 secondary-text-color pb-2 p-0">
                          <div className="row justify-content-center secondary-text-color text-center mx-3">
                            <div className="col-12 col-lg-7 pb-4">
                              <p className="m-0">
                                Договорът ще бъде изпратен на твоя имейл, заедно
                                с инструкции за достъп до твоята{" "}
                                <Button
                                  aria-describedby={id}
                                  onClick={handleClick}
                                  component="span"
                                  style={{
                                    color: "#00915A",
                                    textTransform: "none",
                                    padding: 0,
                                  }}
                                >
                                  Инфо Карта
                                </Button>
                                <BasePopup id={id} open={open} anchor={anchor}>
                                  <PopupBody>
                                    <div style={{ width: "300px" }}>
                                      Инфо Картa ти дава всичко важно за кредита
                                      и плащанията ти на едно място в телефона,
                                      безплатно. Получаваш я бързо и лесно, с
                                      няколко клика.
                                    </div>
                                  </PopupBody>
                                </BasePopup>
                                . Благодарим ти, че избра ПБ Лични финанси за
                                свой партньор!
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display:
                          props.creditInfo?.merchantReturnURL === null
                            ? "none"
                            : "inline-block",
                      }}
                    >
                      <div className="col-12 mt-sm-10 pt-5 pb-4 text-center d-flex justify-content-center">
                        <button
                          type="submit"
                          style={{
                            width: "300px",
                          }}
                          onClick={redirectTo}
                          className={
                            props.merchantPartner.valueOf() === MP.Emag
                              ? "btn-large-primary-emag"
                              : "btn-large-primary"
                          }
                        >
                          {props.merchantPartner.valueOf() === MP.Emag
                            ? "Към eMAG"
                            : "Към магазина"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

const PopupBody = styled("div")(
  ({ theme }) => `
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  box-shadow: ${
    theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  z-index: 1;
`
);

export default ThankYou;
