import { useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { mainTheme } from "./theme";
import IdentityImages from "./identity-images";
import PdfViewer from "./pdf-viewer";
import OTP from "./otp";
import ThankYou from "./thank-you";
import WaitingScreen from "./waiting-screen";
import { ApplicationHeader } from "./header";
import { ApplicationFooter } from "./footer";
import { Error } from "./error";
import { useAuth } from "../src/context/AuthContext";
import React from "react";

interface PdfSource {
  value: string;
}

interface EvrotrustAccessDataParams {
  vendorNumber: string;
  token: string;
  encData: string;
  colorData: string;
}

export interface CreditInfo {
  id: string;
  code: string;
  guid: string;
  customerEGN: string;
  customerPhone: string;
  submittedDate: string;
  type: string;
  currentDisposableAmount: number;
  merchantCode: string;
  merchantName: string;
  merchantReturnURL: string;
  isECommerce: boolean;
  isEcomInPOS: boolean;
}

export enum MP {
  Default,
  Emag,
}

export enum CurrentStep {
  Blank = 0,
  Begin = 1,
  IDCardUpload = 10,
  WebSDK = 20,
  GetIdentificationData = 25,
  RedirectBackToECommerce = 28,
  GetIdentificationDataSuccess = 26,
  ShowPDF = 30,
  SignDocReferenceId = 45,
  VerifyOTP = 50,
  OneTimeSign = 40,
  ThankyouPage = 800,
}

export default function Zone() {
  const auth = useAuth();
  const [pdfData, setPdfData] = useState<PdfSource>({ value: "" });
  const [currentStep, setCurrentStep] = useState<CurrentStep | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSystemError, setIsSystemError] = useState<boolean>(false);
  const submitEvrotrustWebSDKInputRef = React.useRef<HTMLInputElement>(null);
  const [evrotrustData, setEvrotrustAccessData] =
    useState<EvrotrustAccessDataParams | null>(null);
  const [creditInfo, setCreditInfo] = useState<CreditInfo | null>(null);
  const [merchantPartner, setMerchantPartner] = useState<MP>(MP.Default);

  const queryParameters = new URLSearchParams(window.location.search);

  const reference_id = queryParameters.get("reference_id");
  const external_reference_id = queryParameters.get("external_reference_id");
  const status = queryParameters.get("status");

  const handleSetCurrentStep = (currentStep: CurrentStep) => {
    setCurrentStep(CurrentStep.Blank);
    setNextStep(currentStep);
  };

  async function getCreditInfo() {
    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/CreditInfo-GetCreditInfo`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response: CreditInfo = await data.json();
          if (response.merchantCode === "05151-0000") {
            mainTheme.palette.primary.main = "#091cc9";
            setMerchantPartner(MP.Emag);
          }
          setCreditInfo(response);
          console.log(response);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
      });
  }

  async function getEvrotrustAccessData() {
    setIsLoading(true);
    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/WebSDK-GetTokenAndAccessDataService`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response: EvrotrustAccessDataParams = await data.json();
          setEvrotrustAccessData(response);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function getECommReturnURL() {
    setIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/SignManager-GetECommReturnURL`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response: any = await data.json();
          console.log(response.returnURL);
          await setNextStep(CurrentStep.RedirectBackToECommerce, true);
          if (response.returnURL !== null || response.returnURL !== undefined) {
            window.location.href = response.returnURL;
          } else {
            console.log("returnURL is null");
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function getContract() {
    setIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/PDFGenerator-GetContractPDF`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response: any = await data.json();
          setPdfData(() => {
            return { value: response };
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function getNextStep() {
    setIsLoading(true);

    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/SignManager-GetNextStepSigning`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response: any = await data.json();
          console.log(response);

          if (
            response.Enum === "ThankyouPage" &&
            response.ProcessId === "800"
          ) {
            await setNextStep(CurrentStep.ThankyouPage);
          }

          mapCurrentStep(response.Enum);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function setNextStep(
    currentStep: CurrentStep,
    preventGetNextStep: boolean = false
  ) {
    setIsLoading(true);

    await fetch(
      new Request(
        `${process.env.REACT_APP_SZ_BFF_URL}/CurrentStep-SetStep?subscriptionZoneStepId=${currentStep}`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "x-csrf": "1",
          },
        }
      )
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          if (preventGetNextStep === false) {
            await getNextStep();
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function oneTimeSign() {
    setIsLoading(true);
    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/SendDocumentOfflineFile-SendDocument`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          await setNextStep(CurrentStep.OneTimeSign);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
        setIsLoading(false);
      });
  }

  async function setReferenceID(referenceID: string) {
    await fetch(
      `${process.env.REACT_APP_SZ_BFF_URL}/UserEvrotrust-SetReferenceID?referenceID=${referenceID}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "x-csrf": "1",
        },
      }
    )
      .then(async (data) => {
        if (data.status === 400) {
          setIsSystemError(true);
        } else {
          const response = await data.json();
          if (response.result === true) {
            await setNextStep(CurrentStep.WebSDK);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsSystemError(true);
      });
  }

  function mapCurrentStep(currentStep: string) {
    console.log(currentStep);
    if (currentStep === "IDCardUpload") {
      setCurrentStep(CurrentStep.IDCardUpload);
    } else if (currentStep === "WebSDK") {
      setCurrentStep(CurrentStep.WebSDK);
    } else if (currentStep === "GetIdentificationData") {
      setCurrentStep(CurrentStep.GetIdentificationData);
    } else if (currentStep === "GetIdentificationDataSuccess") {
      setCurrentStep(CurrentStep.GetIdentificationDataSuccess);
    } else if (currentStep === "ShowPDF") {
      setCurrentStep(CurrentStep.ShowPDF);
    } else if (currentStep === "SignDocReferenceId") {
      setCurrentStep(CurrentStep.SignDocReferenceId);
    } else if (currentStep === "VerifyOTP") {
      setCurrentStep(CurrentStep.VerifyOTP);
    } else if (currentStep === "OneTimeSign") {
      setCurrentStep(CurrentStep.OneTimeSign);
    } else if (currentStep === "RedirectBackToECommerce") {
      setCurrentStep(CurrentStep.RedirectBackToECommerce);
    } else if (currentStep === "ThankyouPage") {
      setCurrentStep(CurrentStep.ThankyouPage);
    }
  }

  useEffect(() => {
    async function checkProcess() {
      if (currentStep === CurrentStep.ShowPDF) {
        await getContract();
      } else if (currentStep === CurrentStep.OneTimeSign) {
        await oneTimeSign();
      } else if (
        currentStep === CurrentStep.WebSDK &&
        reference_id === null &&
        external_reference_id === null &&
        status === null
      ) {
        await getEvrotrustAccessData();
      } else if (currentStep === CurrentStep.GetIdentificationData) {
        await setNextStep(CurrentStep.GetIdentificationData);
      } else if (currentStep === CurrentStep.GetIdentificationDataSuccess) {
        await setNextStep(CurrentStep.GetIdentificationDataSuccess);
      } else if (currentStep === CurrentStep.SignDocReferenceId) {
        await setNextStep(CurrentStep.SignDocReferenceId);
      } else if (currentStep === CurrentStep.RedirectBackToECommerce) {
        await getECommReturnURL();
      }
    }

    checkProcess();
  }, [currentStep]);

  useEffect(() => {
    async function getData() {
      await getCreditInfo();

      await getNextStep();

      //TEST only
      // setNextStep(CurrentStep.Begin);
      // setCurrentStep(CurrentStep.RedirectBackToECommerce);
    }

    getData();
  }, []);

  useEffect(() => {
    async function checkProcess() {
      if (
        creditInfo !== null &&
        reference_id !== null &&
        external_reference_id !== null &&
        status !== null &&
        status === "2"
      ) {
        await setReferenceID(reference_id);
      }
    }

    checkProcess();
  }, [creditInfo]);

  useEffect(() => {
    if (
      evrotrustData?.token !== undefined &&
      evrotrustData?.vendorNumber !== undefined &&
      evrotrustData?.encData !== undefined
    ) {
      submitEvrotrustWebSDKInputRef.current?.click();
    }
  }, [
    evrotrustData?.token,
    evrotrustData?.vendorNumber,
    evrotrustData?.encData,
  ]);

  useEffect(() => {
    if (isLoading === true) {
    }
  }, [isLoading]);

  function handleIsLoading(isLoading: boolean) {
    setIsLoading(isLoading);
  }

  function handleIsSystemError(isSystemError: boolean) {
    setIsSystemError(isSystemError);
  }

  return auth.isAuth ? (
    <>
      <ThemeProvider theme={mainTheme}>
        <ApplicationHeader
          merchantPartner={merchantPartner}
        ></ApplicationHeader>
        {currentStep !== CurrentStep.WebSDK && (
          <div className="d-flex-center-content progress-bar-dots">
            <div className="circle step-complete"></div>
            <div
              className={
                currentStep === CurrentStep.IDCardUpload
                  ? "circle step-active"
                  : currentStep !== null &&
                    currentStep > CurrentStep.IDCardUpload
                  ? "circle step-complete"
                  : "circle step-incomplete"
              }
            ></div>
            <div
              className={
                currentStep === CurrentStep.ShowPDF
                  ? "circle step-active"
                  : currentStep !== null && currentStep > CurrentStep.ShowPDF
                  ? "circle step-complete"
                  : "circle step-incomplete"
              }
            ></div>
            <div
              className={
                currentStep === CurrentStep.VerifyOTP
                  ? "circle step-active"
                  : currentStep !== null && currentStep > CurrentStep.VerifyOTP
                  ? "circle step-complete"
                  : "circle step-incomplete"
              }
            ></div>
            <div
              className={
                currentStep === CurrentStep.ThankyouPage
                  ? "circle step-active"
                  : currentStep !== null &&
                    currentStep > CurrentStep.ThankyouPage
                  ? "circle step-complete"
                  : "circle step-incomplete"
              }
            ></div>
          </div>
        )}
        {(isLoading === true || currentStep === CurrentStep.WebSDK) &&
          isSystemError === false && (
            <WaitingScreen merchantPartner={merchantPartner}></WaitingScreen>
          )}

        {currentStep === CurrentStep.IDCardUpload &&
          isLoading === false &&
          isSystemError === false && (
            <IdentityImages
              merchantPartner={merchantPartner}
              handleIsLoading={handleIsLoading}
              handleIsSystemError={handleIsSystemError}
              handleSetNextStep={handleSetCurrentStep}
            ></IdentityImages>
          )}

        {currentStep === CurrentStep.ShowPDF &&
          isLoading === false &&
          isSystemError === false &&
          pdfData.value !== "" && (
            <PdfViewer
              fileUrl={pdfData.value}
              merchantPartner={merchantPartner}
              handleSetNextStep={handleSetCurrentStep}
            ></PdfViewer>
          )}

        {currentStep === CurrentStep.VerifyOTP &&
          isLoading === false &&
          isSystemError === false && (
            <OTP
              merchantPartner={merchantPartner}
              handleSetNextStep={handleSetCurrentStep}
              handleIsSystemError={handleIsSystemError}
              handleIsLoading={handleIsLoading}
              creditInfo={creditInfo}
            ></OTP>
          )}

        {currentStep === CurrentStep.ThankyouPage &&
          isLoading === false &&
          isSystemError === false && (
            <ThankYou
              merchantPartner={merchantPartner}
              handleIsSystemError={handleIsSystemError}
              handleSetNextStep={handleSetCurrentStep}
              creditInfo={creditInfo}
            />
          )}

        {isSystemError === true && <Error traceId=""></Error>}

        <ApplicationFooter></ApplicationFooter>
      </ThemeProvider>

      {evrotrustData?.token !== undefined &&
        evrotrustData?.vendorNumber !== undefined &&
        evrotrustData?.encData !== undefined &&
        evrotrustData?.colorData !== undefined && (
          <form method="post" action="https://et.test.websdk.iteco.bg/">
            <input
              type="hidden"
              name="token"
              value={evrotrustData?.token}
              onChange={() => {}}
            />
            <input
              type="hidden"
              name="vendor-number"
              value={evrotrustData?.vendorNumber}
              onChange={() => {}}
            />
            <input
              type="hidden"
              name="enc-data"
              value={evrotrustData?.encData}
              onChange={() => {}}
            />
            <input
              type="hidden"
              name="color-data"
              value={evrotrustData?.colorData}
              onChange={() => {}}
            />
            <input
              type="submit"
              value=""
              ref={submitEvrotrustWebSDKInputRef}
              style={{ display: "none" }}
            />
          </form>
        )}
    </>
  ) : (
    <></>
  );
}
