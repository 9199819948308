import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  ToolbarSlot,
  TransformToolbarSlot,
  toolbarPlugin,
} from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { CurrentStep, MP } from "./zone";

interface Source {
  fileUrl: string;
  merchantPartner: MP;
  handleSetNextStep: (step: number) => void;
}

const PdfViewer: React.FC<Source> = ({
  fileUrl,
  merchantPartner,
  handleSetNextStep: handleSetCurrentStep,
}) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    EnterFullScreen: () => <></>,
    SwitchTheme: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  const base64 = "data:application/pdf;base64," + fileUrl;
  const pdfContentType = "application/pdf";

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substring(
      `data:${pdfContentType};base64,`.length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: pdfContentType });
  };

  const url = URL.createObjectURL(base64toBlob(base64));

  const signContract = () => {
    handleSetCurrentStep(CurrentStep.ShowPDF);
  };

  return (
    <>
      <div className="container flex-shrink-0">
        <main role="main">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div>
                <ul className="progress-bar-wrapper">
                  <li className="step step-complete">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        id="icon_personal_information"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          id="Rectangle_3410"
                          data-name="Rectangle 3410"
                          width="40"
                          height="40"
                          className="icon-background-color"
                        ></rect>
                        <g
                          id="Group_9799"
                          data-name="Group 9799"
                          transform="translate(0 4.766)"
                        >
                          <path
                            id="Path_7915"
                            data-name="Path 7915"
                            d="M158.165,242.767H125.983a3.913,3.913,0,0,0-3.909,3.909v22.648a3.915,3.915,0,0,0,3.909,3.91h32.182a3.914,3.914,0,0,0,3.909-3.91V246.673A3.914,3.914,0,0,0,158.165,242.767Zm2.043,26.557a2.047,2.047,0,0,1-2.043,2.043H125.983a2.047,2.047,0,0,1-2.042-2.043V246.673a2.043,2.043,0,0,1,2.042-2.043h32.182a2.043,2.043,0,0,1,2.043,2.043Z"
                            transform="translate(-122.074 -242.767)"
                            className="icon-secondary-color"
                          ></path>
                          <path
                            id="Path_7916"
                            data-name="Path 7916"
                            d="M180.725,262.353a.933.933,0,0,0-.933-.932h-8.7a.933.933,0,1,0,0,1.865h8.71a.933.933,0,0,0,.933-.932Z"
                            transform="translate(-146.113 -252.094)"
                            className="icon-secondary-color"
                          ></path>
                          <path
                            id="Path_7917"
                            data-name="Path 7917"
                            d="M180.725,272.3a.933.933,0,0,0-.933-.932h-8.7a.933.933,0,1,0,0,1.866h8.71a.933.933,0,0,0,.933-.933Z"
                            transform="translate(-146.113 -257.068)"
                            className="icon-secondary-color"
                          ></path>
                          <path
                            id="Path_7918"
                            data-name="Path 7918"
                            d="M180.725,282.25a.933.933,0,0,0-.933-.933h-8.7a.933.933,0,1,0,0,1.866h8.71a.933.933,0,0,0,.933-.932Z"
                            transform="translate(-146.113 -262.042)"
                            className="icon-secondary-color"
                          ></path>
                          <path
                            id="Union_67"
                            data-name="Union 67"
                            d="M-5924.194,1441.577h-6.612c-1.067,0-1.4-.451-1.074-1.443.4-1.2.8-2.4,1.213-3.589a2.638,2.638,0,0,1,2.645-1.889q3.9-.018,7.8,0a2.556,2.556,0,0,1,2.555,1.784c.46,1.255.884,2.523,1.3,3.795.275.847-.091,1.337-.991,1.341q-1.314,0-2.629,0Zm.084-8.638h-.07a4.353,4.353,0,0,1-4.31-4.393,4.351,4.351,0,0,1,4.394-4.312,4.352,4.352,0,0,1,4.312,4.393c0,.034,0,.07,0,.1a4.264,4.264,0,0,1-4.263,4.208Z"
                            transform="translate(5937.69 -1417.734)"
                            className="icon-accent-color"
                            stroke="rgba(0,0,0,0)"
                            strokeWidth="1"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <span className="step-text section-heading">Договор</span>
                  </li>
                  <li className="step step-complete">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        id="icon_otp_code"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          id="Rectangle_3577"
                          data-name="Rectangle 3577"
                          width="40"
                          height="40"
                          className="icon-background-color"
                        ></rect>
                        <g id="Group_10731" data-name="Group 10731">
                          <path
                            id="Path_8934"
                            data-name="Path 8934"
                            d="M33.946,26.115a6.047,6.047,0,1,0-6.053-6.054A6.057,6.057,0,0,0,33.946,26.115Z"
                            transform="translate(-13.946 -7.01)"
                            className="icon-accent-color"
                          ></path>
                          <path
                            id="Path_8935"
                            data-name="Path 8935"
                            d="M40,50.93V49.716a6.66,6.66,0,0,0-6.653-6.653H24.865a6.66,6.66,0,0,0-6.653,6.653V50.93a1.816,1.816,0,0,0,1.813,1.813H38.187A1.816,1.816,0,0,0,40,50.93Z"
                            transform="translate(-9.106 -21.531)"
                            className="icon-accent-color"
                          ></path>
                          <path
                            id="Path_8936"
                            data-name="Path 8936"
                            d="M0,36.347v-6.1H2.506v6.1a1.149,1.149,0,0,0,1.148,1.147h6.1V40h-6.1A3.658,3.658,0,0,1,0,36.347ZM9.752,2.506V0h-6.1A3.658,3.658,0,0,0,0,3.654v6.1H2.506v-6.1A1.149,1.149,0,0,1,3.654,2.506ZM37.494,3.654v6.1H40v-6.1A3.658,3.658,0,0,0,36.347,0h-6.1V2.506h6.1A1.149,1.149,0,0,1,37.494,3.654ZM40,36.347v-6.1H37.494v6.1a1.149,1.149,0,0,1-1.147,1.147h-6.1V40h6.1A3.658,3.658,0,0,0,40,36.347Z"
                            className="icon-secondary-color"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <span className="step-text section-heading">
                      Идентификация
                    </span>
                  </li>
                  <li className="step step-complete">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        id="icon_more_info"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          id="Rectangle_5769"
                          data-name="Rectangle 5769"
                          width="40"
                          height="40"
                          className="icon-background-color"
                        ></rect>
                        <g
                          id="Group_10736"
                          data-name="Group 10736"
                          transform="translate(6.353)"
                        >
                          <g
                            id="Group_10735"
                            data-name="Group 10735"
                            transform="translate(0 3.101)"
                          >
                            <path
                              id="Path_8750"
                              data-name="Path 8750"
                              d="M58.253,9.985v16.2a8.452,8.452,0,0,1,1.987.269V9.985A3.788,3.788,0,0,0,56.455,6.2h-3.25V8c0,.064-.015.125-.019.188h3.269A1.8,1.8,0,0,1,58.253,9.985Z"
                              transform="translate(-32.946 -6.201)"
                              className="icon-secondary-color"
                            ></path>
                            <path
                              id="Path_8751"
                              data-name="Path 8751"
                              d="M30.8,39.245H16.49a1.8,1.8,0,0,1-1.8-1.8V9.985a1.8,1.8,0,0,1,1.8-1.8h3.27c0-.063-.02-.124-.02-.188V6.2H16.49a3.788,3.788,0,0,0-3.784,3.784V37.448a3.788,3.788,0,0,0,3.784,3.785H32.6A8.5,8.5,0,0,1,30.8,39.245Z"
                              transform="translate(-12.706 -6.201)"
                              className="icon-secondary-color"
                            ></path>
                          </g>
                          <path
                            id="Path_8752"
                            data-name="Path 8752"
                            d="M39.007,22.226H25.292a.994.994,0,0,1-.993-.993h0a.994.994,0,0,1,.993-.994H39.007a.994.994,0,0,1,.993.994h0A.994.994,0,0,1,39.007,22.226ZM24.3,16.062h0a.993.993,0,0,0,.993.993H39.007A.993.993,0,0,0,40,16.062h0a.994.994,0,0,0-.993-.994H25.292A.994.994,0,0,0,24.3,16.062Zm0-5.17h0a.994.994,0,0,0,.993.993H39.007A.994.994,0,0,0,40,10.892h0a.994.994,0,0,0-.993-.994H25.292A.994.994,0,0,0,24.3,10.892Zm0,15.51h0a.993.993,0,0,0,.993.993h7.832a.994.994,0,0,0,.994-.993h0a.994.994,0,0,0-.994-.993H25.292A.993.993,0,0,0,24.3,26.4ZM43.69,23.083a8.458,8.458,0,1,0,8.459,8.458A8.458,8.458,0,0,0,43.69,23.083Zm3.916,9.452H44.685v2.922a.994.994,0,0,1-1.988,0V32.535H39.775a.994.994,0,1,1,0-1.987H42.7V27.626a.994.994,0,1,1,1.988,0v2.922h2.922a.994.994,0,0,1,0,1.987ZM35.925,2.149H34.3a2.149,2.149,0,1,0-4.3,0H28.372A1.483,1.483,0,0,0,26.89,3.632V4.4a1.483,1.483,0,0,0,1.483,1.483h7.553A1.483,1.483,0,0,0,37.408,4.4V3.632A1.483,1.483,0,0,0,35.925,2.149Z"
                            transform="translate(-18.502)"
                            className="icon-accent-color"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <span className="step-text section-heading">
                      Допълнителни Данни
                    </span>
                  </li>
                  <li className="step step-complete">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <g transform="translate(0 0.819)">
                          <path
                            className="icon-accent-color"
                            d="M17,13.525h0a3.473,3.473,0,0,1-3.473-3.473V5.111A3.473,3.473,0,0,1,17,1.638h0a3.472,3.472,0,0,1,3.472,3.473v4.942A3.472,3.472,0,0,1,17,13.525Zm.587,23V31.586a3.473,3.473,0,0,0-3.473-3.472h0a3.473,3.473,0,0,0-3.473,3.472v4.942A3.473,3.473,0,0,0,14.114,40h0A3.473,3.473,0,0,0,17.587,36.528ZM37,23.29V18.348a3.473,3.473,0,0,0-3.473-3.472h0a3.473,3.473,0,0,0-3.473,3.472V23.29a3.473,3.473,0,0,0,3.473,3.472h0A3.473,3.473,0,0,0,37,23.29Z"
                            transform="translate(-5.32 -1.638)"
                          ></path>
                          <path
                            className="icon-secondary-color"
                            d="M4.32,40H1.051a1.052,1.052,0,0,1,0-2.1H4.32Zm34.628-2.1H13.267V40H38.949a1.052,1.052,0,0,0,0-2.1ZM23.729,24.66H1.051a1.052,1.052,0,0,0,0,2.1H23.729Zm15.22,0H32.675v2.1h6.274a1.052,1.052,0,0,0,0-2.1ZM7.207,11.422H1.051a1.051,1.051,0,1,0,0,2.1H7.207Zm31.742,0h-22.8v2.1h22.8a1.051,1.051,0,0,0,0-2.1Z"
                            transform="translate(0 -6.53)"
                          ></path>
                        </g>
                        <rect
                          className="icon-background-color"
                          width="40"
                          height="40"
                        ></rect>
                      </svg>
                    </span>
                    <span className="step-text section-heading">
                      Параметри на кредита
                    </span>
                  </li>
                  <li className="step step-active">
                    <span className="step-item">
                      <svg
                        className="icon-object icon-m icon-progress"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                      >
                        <rect
                          className="icon-background-color"
                          width="40"
                          height="40"
                        ></rect>
                        <path
                          className="icon-accent-color"
                          d="M772.808,3.726a2,2,0,0,0-2.824.2L749.571,27.321,737.16,16.408a2,2,0,1,0-2.62,3.029l13.924,12.222a2,2,0,0,0,2.825-.2L773.012,6.55A2,2,0,0,0,772.808,3.726Z"
                          transform="translate(-733.674 -1.619)"
                        ></path>
                        <path
                          className="icon-secondary-color"
                          d="M773.262,20a4.59,4.59,0,0,1,.242,1.463V42.191a4.619,4.619,0,0,1-4.614,4.615H748.161a4.62,4.62,0,0,1-4.615-4.615V31.335l2,1.73v9.125a2.618,2.618,0,0,0,2.615,2.615H768.89a2.617,2.617,0,0,0,2.614-2.615V22.03Zm-25.1-3.151a4.619,4.619,0,0,0-4.615,4.614V23.4l2,1.694V21.462a2.617,2.617,0,0,1,2.615-2.614h18.156l1.73-2Z"
                          transform="translate(-738.523 -8.424)"
                        ></path>
                      </svg>
                    </span>
                    <span className="step-text section-heading">Договор</span>
                  </li>
                </ul>
              </div>
              <div className="container rounded bg-white shadow my-4">
                <div className="main-content">
                  <div className="row justify-content-sm-center text-md-center mb-10 pb-4">
                    <div className="col-12 col-md-8">
                      <h1
                        className="pb-3 pt-4 m-0 font-weight-bold"
                        style={{ textAlign: "center" }}
                      >
                        Прегледай своя договор
                      </h1>
                      <p
                        className="mb-0 text-body-2 secondary-text-color"
                        style={{ textAlign: "center" }}
                      >
                        Запознай се внимателно с условията.
                      </p>
                    </div>
                  </div>
                  <div>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <div
                        className="rpv-core__viewer"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                          display: "flex",
                          flexDirection: "column",
                          height: "500px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            alignItems: "center",
                            backgroundColor: "#eeeeee",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                            display: "flex",
                            padding: "4px",
                          }}
                        >
                          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                          }}
                        >
                          <Viewer
                            fileUrl={url}
                            plugins={[toolbarPluginInstance]}
                          />
                        </div>
                      </div>
                    </Worker>
                  </div>

                  <div className="row justify-content-center pt-4">
                    <div className="col-12">
                      <div></div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      className="mt-sm-10 pt-4 pb-4 d-flex justify-content-center align-items-centerr"
                    >
                      <button
                        type="submit"
                        id="submitButton"
                        className={
                          merchantPartner.valueOf() === MP.Emag
                            ? "btn-large-primary-emag"
                            : "btn-large-primary"
                        }
                        onClick={signContract}
                      >
                        Подпиши
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default PdfViewer;
