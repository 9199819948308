import { Claim } from "../types/auth";

export default class AuthService {
  private bffUrl?: string;
  private webUrl?: string;
  private userUrl: string;
  private loginUrl: string;

  private commonHeaders: Headers;

  private queryParameters: URLSearchParams;

  constructor() {
    this.bffUrl = process.env.REACT_APP_SZ_BFF_URL;
    this.webUrl = process.env.REACT_APP_SZ_WEB_URL;
    if (!this.bffUrl || !this.webUrl) {
      throw new Error("Bff URL and Web URL must be defined.");
    }
    this.queryParameters = new URLSearchParams(window.location.search);
    const t = this.queryParameters.get("t");
    this.userUrl = `${this.bffUrl}/bff/user`;
    this.loginUrl = `${this.bffUrl}/bff/login?t=${t}&returnUrl=${this.webUrl}`;

    this.commonHeaders = new Headers({
      "x-csrf": "1",
    });
  }

  async getUserClaims() {
    var req = new Request(this.userUrl, {
      headers: this.commonHeaders,
      credentials: "include",
    });
    var resp = await fetch(req);
    if (resp.ok) {
      let claims: Claim[] = await resp.json();
      return claims;
    } else if (resp.status === 401) {
      this.login();
    }
  }

  async checkUser() {
    var req = new Request(this.userUrl, {
      headers: this.commonHeaders,
      credentials: "include",
    });
    var resp = await fetch(req);
    if (resp.ok) {
      return true;
    } else if (resp.status === 401) {
      return false;
    }
  }

  login() {
    window.location.replace(this.loginUrl);
  }

  async logout() {
    try {
      const response = await fetch(this.userUrl, {
        headers: this.commonHeaders,
        credentials: "include",
      });

      if (response.ok) {
        const claims: Claim[] = await response.json();
        console.log(claims);
        let logoutUrlClaim = claims.find(
          (claim: Claim) => claim.type === "bff:logout_url"
        );
        if (logoutUrlClaim && this.bffUrl) {
          const queryParameters = new URLSearchParams(window.location.search);
          const t = queryParameters.get("t");
          this.loginUrl =
            this.bffUrl + ((logoutUrlClaim.value)?.replace("/bff", "")) + "&returnUrl=" + this.webUrl + ((t != null) ? `?t=${t}` : "");
          console.log(this.loginUrl);
        }
      }
    } catch (error) {
      console.error("Error checking user status", error);
    }

    window.location.replace(this.loginUrl);
  }
}
