"use client";

import React, { createContext, useContext, useState, useEffect } from "react";
import AuthService from "../auth/AuthService";

const AuthContext = createContext({
  isAuth: false,
  login: () => { },
  logout: () => { },
});

const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const t = queryParameters.get("t");
  const [isAuth, setIsAuth] = useState(false);
  const authService = new AuthService();
  const login = () => authService.login();
  const logout = () => authService.logout();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const r = await authService.checkUser();
        if (t != null && r === true) {
          logout();
        } else {
          setIsAuth(r || false);
          if (r === false) {
            login();
          }
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    fetchData();
  }, []);

  const value = { isAuth, login, logout };
  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
}

export { useAuth, AuthProvider };
